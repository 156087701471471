<template>
	<div id="top">
		<nav
			class="main-nav fix-on-scroll"
			:class="{
				'show-mobile-nav': showMobileNav,
				'no-heading-image': page && !page.headingImage,
			}"
		>
			<div class="row">
				<div class="columns column12">
					<div class="nav-bar">
						<nuxt-link class="logo" :to="{ path: localePath('/'), hash: '#top' }">
							<img src="/logo.png" alt="logo" />
						</nuxt-link>
						<div class="nav-content">
							<ul>
								<li class="menu-close" @click="hideMobileNav">X</li>
								<li v-for="item in topmenu[locale]" :key="item.filename" @click="hideMobileNav">
									<nuxt-link :to="item.filename">
										{{ item.header }}
									</nuxt-link>
								</li>
								<li class="lang-nav mobile-only">
									<div v-for="item in langmenu" :key="item.filename" class="language">
										<nuxt-link
											class="flag"
											:class="item.language"
											:to="item.filename"
											@click="hideMobileNav"
										>
											<span>{{ item.language }}</span>
										</nuxt-link>
									</div>
								</li>
							</ul>
						</div>
						<a
							v-if="defaults[locale].website.phone"
							class="nav-contact-link phone"
							:href="`tel:${$phoneRaw(defaults[locale].website.phone)}`"
						>
							<font-awesome-icon icon="fa-light fa-phone" size="1x" />
						</a>
						<a
							v-if="defaults[locale].website.email"
							class="nav-contact-link email"
							:href="`mailto:${defaults[locale].website.email}`"
						>
							<font-awesome-icon icon="fa-light fa-envelope" size="1x" />
						</a>
						<div v-if="page && langmenu.length" class="language-navigation" @click="toggleLanguageMenu">
							<div class="lang-select">
								<span>{{ locale }}</span>
								<font-awesome-icon :icon="`fal fa-chevron-down`" size="1x" />
								<div class="lang-wrapper" :class="{ active: showLanguageMenu }">
									<div v-for="item in langmenu" :key="item.filename" class="language">
										<nuxt-link
											class="flag"
											:class="item.language"
											:to="item.filename"
											@click="hideMobileNav"
										>
											<span>{{ item.language }}</span>
										</nuxt-link>
									</div>
								</div>
							</div>
						</div>
						<div class="buttons">
							<div class="menu-button mobile-only" @click="toggleMobileNav">
								<font-awesome-icon class="icon" icon="fal fa-bars" size="1x" />
								<span>{{ $t('buttonMenu') }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();
const localePath = useLocalePath();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}

const showMobileNav = ref(false);
const showLanguageMenu = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const langmenu = computed(() => {
	return (page.value && page.value.langmenu) ?? [];
});

const toggleMobileNav = () => {
	showMobileNav.value = !showMobileNav.value;
};

const hideMobileNav = () => {
	showMobileNav.value = false;
};

const toggleLanguageMenu = () => {
	showLanguageMenu.value = !showLanguageMenu.value;
};
</script>

<style lang="scss" scoped>
.mobile-only {
	display: none !important;
}

.main-nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	color: #fff;
	transition: background 0.3s ease-in-out;

	ul li {
		display: inline-block;
	}

	&.scroll,
	&.no-heading-image {
		background: var(--accent-color);
	}
}

.nav-bar {
	display: flex;
	align-items: center;
	padding: 8px 0;
	z-index: 9;
	position: relative;

	.nav-contact-link {
		margin: 0 10px;
		color: var(--text-color-light);
		position: relative;
		top: 1px;

		&:hover {
			opacity: 0.5;
		}
	}

	.buttons {
		display: flex;
		align-items: center;
		gap: 10px;
		margin: 0 10px;

		.button {
			padding: 15px 25px;
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.icon {
			display: none;
			margin-right: 5px;
		}
	}
}

.logo {
	padding: 0;
	text-decoration: none;
	margin: 18px 0;
	z-index: 1;

	img {
		max-width: 250px;
	}
}

.nav-content {
	flex-grow: 1;
	text-align: center;

	ul {
		line-height: normal;
	}

	a {
		text-decoration: none;
		margin: 0 15px;
		padding-bottom: 5px;
		color: var(--text-color-light);
		font-size: 24px;
		text-transform: uppercase;
		font-weight: 900;

		&.router-link-active:not(.flag),
		&:hover {
			border-bottom: 3px solid #fff;
		}
	}

	.menu-close {
		color: var(--text-color-light);
		display: none;
		font-size: 34px;
		cursor: pointer;
		position: absolute;
		top: 26px;
		width: auto;
		right: 32px;
		font-weight: 300;
		padding: 0;
		margin: 0;
	}
}

.lang-nav {
	margin: 0 0 25px;

	.language {
		margin: 20px 0;

		.flag::before {
			content: '';
			width: 16px;
			position: relative;
			height: 16px;
			margin-right: 6px;
			display: inline-block;
		}

		.flag.en::before {
			background: url('~/assets/images/en.png') no-repeat;
		}

		.flag.nl::before {
			background: url('~/assets/images/nl.png') no-repeat;
		}

		.flag.de::before {
			background: url('~/assets/images/de.png') no-repeat;
		}

		.flag.fr::before {
			background: url('~/assets/images/fr.png') no-repeat;
		}

		.flag.es::before {
			background: url('~/assets/images/es.png') no-repeat;
		}
	}

	img {
		width: 16px;
		float: left;
		margin: 0 5px 0 0;
	}

	a {
		width: 100%;
		float: left;
		color: var(--body-color);
		text-decoration: none;
		margin: 4px 0;
		text-transform: uppercase;

		&.active {
			color: var(--cta-color);
		}
	}
}

.lang-nav-sel {
	box-shadow: none;
	cursor: pointer;
	width: 30px;
	padding: 0 4px 0 0;
	color: #fff;
	border: none;
	text-transform: uppercase;
	font-size: 12px;
	font-family: sans-serif;
	appearance: none;
	background: url('~/assets/images/dropdown.png') right 1px center no-repeat;
	background-size: 7px;
	transition: color 0.3s ease-in-out;

	option {
		color: var(--body-color);
	}

	&::-ms-expand {
		display: none;
	}
}

.lang-select {
	margin: 0 10px;
	position: relative;
	cursor: pointer;
	text-transform: uppercase;
	font-size: 23px;
	font-weight: 600;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	transition: opacity 0.3s ease-in-out;

	&:hover > span {
		opacity: 0.5;
	}

	span {
		margin: 0 4px 0 0;
	}

	a {
		text-decoration: none;
	}

	svg {
		font-size: 12px;
	}
}

.lang-wrapper {
	display: none;
	position: absolute;
	top: 35px;
	left: 3px;
	right: 3px;
	padding: 7px 0;
	text-align: center;
	background: var(--primary-background-color);
	box-shadow: 0 18px 18px rgb(0 0 0 / 10%);
	z-index: 90;
	min-width: 50px;

	&.active,
	a {
		display: flex;
		flex-flow: column nowrap;
		place-content: space-between center;
		color: #000 !important;
		font-size: 18px;
		gap: 5px;
	}

	a {
		width: 100%;
		flex-direction: row;
	}

	img {
		width: 16px;
		height: 16px;
		margin: 2px 0 0;
	}
}

@media (max-width: 1280px) {
	.nav-bar a {
		font-size: 24px;
		color: var(--text-color-light);
	}
}

@media (max-width: 1200px) {
	.menu-button {
		text-transform: uppercase;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		margin: 0 10px 0 0;
		cursor: pointer;

		svg {
			font-size: 23px;
		}

		span {
			margin: 0 0 0 px;
			font-size: 25px;
		}
	}

	.mobile-only {
		display: flex !important;

		&.lang-nav {
			display: inline-block !important;
			margin: 30px 0 0;
			padding: 0 10px;
		}
	}

	.language-navigation {
		display: none;
	}

	.nav-bar .buttons .icon {
		display: unset;
	}

	.main-nav {
		position: fixed !important;
		top: 0;

		&.show-mobile-nav {
			.nav-content ul {
				display: flex;
				flex-direction: column;
				justify-content: center;
				opacity: 1;
				visibility: visible;
				transition: opacity 0.3s ease-in-out;
				background: linear-gradient(210deg, #ae3fff, #ff89b9);

				li:not(.menu-close) {
					width: 100%;
				}

				li:not(.lang-nav, .menu-close) {
					margin: 7px 0;

					a {
						font-size: 33px;
					}
				}
			}
		}

		ul {
			position: fixed;
			inset: 0;
			background: var(--primary-background-color);
			text-align: left;
			padding: 20px;
			opacity: 0;
			visibility: hidden;
			overflow-y: scroll;
		}
	}

	.nav-content .menu-close {
		display: block;
	}

	.logo {
		h3 {
			font-size: 22px;
		}
	}
}

@media (max-width: 880px) {
	.column12 {
		padding: 0;
	}

	.nav-bar {
		.nav-contact-link {
			margin: 0 13px;

			&.email {
				display: none;
			}
		}

		.buttons {
			margin-right: 0;

			.button {
				padding: 16px;
			}

			.menu-button {
				margin-right: 5px;

				.icon {
					font-size: 26px;
				}
			}

			.icon {
				margin-right: 0;
				font-size: 16px;
			}

			span {
				display: none;
			}
		}
	}
}

@media (max-width: 580px) {
	.nav-bar .buttons span {
		display: none;
	}

	.nav-bar .logo {
		padding-right: 20px;
		font-size: 22px;
	}
}
</style>
